import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import UnorderedList from "../../../components/UnorderedList/unordered-list"

const OrderingTools = () => (
  <Layout>
    <SEO title="Ordering Tools" />
    <HeroInner
      title="Ordering Tools"
      imageAlt="Ordering Tools"
      imagePath="services/tailored-services/ordering-tools/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `We are here to help make your ordering process as smooth as possible. By using Blend-Divine’s easy-to-use online search, order, and account management platform, you’re able to free up time and so much more.`,
        `Here’s How:`,
      ]}
    />
    <div className="container mb40">
      <UnorderedList
        items={[
          `Search extensively by language, category, Sales rank’s on different E-commerce Platforms and more`,
          `Order quickly and easily once you are enrolled with us`,
          `Check stock availability in real-time for each warehouse`,
          `Access all available formats for a particular product on one page`,
          `View invoices, track shipments, and make payments`,
          `Browse and shop Blend-Divine’s trade catalogs`,
          `View estimated times of arrival for backorder products`,
          `Ship directly to your customers`,
          `Send gifts to customers’ out-of-town friends and family (gift wrapping is available on request)`,
          `Ship items to most countries in the world`,
          `Order easily 24 hours a day, 7 days a week`,
        ]}
      />
    </div>
  </Layout>
)

export default OrderingTools
