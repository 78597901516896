import React from "react"

import css from "./unordered-list.module.scss"

const UnorderedList = ({ items }) => (
 
  <ul className={css.list}>
    {items &&
      items.map((item, index) => (
        <li key={index} className={css.item}>
          {item}
        </li>
      ))}
  </ul>
)

export default UnorderedList
